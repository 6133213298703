<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          Data
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <b-container fluid class="px-0">
          <b-row>
            <b-col>
              <b-form-textarea
                v-model="text"
                max-rows="50"
                size="sm"
              ></b-form-textarea>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="importData"
            >
              Import
            </b-btn>
          </div>
          <div>
            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'RawModal',
  components: {},
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {},
      text: '',
    };
  },
  methods: {
    dataToText(data) {
      const source = JSON.parse(JSON.stringify(data));
      const fields = {};
      data.fields.forEach((field) => {
        const key = field.field_name.length > 0 ? field.field_name : field.uuid;
        fields[key] = field;
      });
      source.fields = fields;
      const text = JSON.stringify(source, null, 2);
      return text;
    },
    textToData(text) {
      const data = JSON.parse(text);
      data.fields = Object.values(data.fields);
      return data;
    },
    importData() {
      const data = this.textToData(this.text);
      this.$emit('import', data);
      this.onClose();
    },
    onShow() {
      this.text = this.dataToText(this.data.label);
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
